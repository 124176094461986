export default {
  footerV2: {
    container: {
      paddingX: [4, '', '', 10],
      paddingY: 8,
      backgroundColor: 'black',
      flexDirection: ['column', 'column', 'row', 'row'],
      justifyContent: ['center', '', '', 'flex-end'],
      alignItems: 'center',
      borderTop: '2px solid',
      '.contactDetails-container': {
        justifyContent: 'center',
        textAlign: 'center'
      },
      '.multiButtonContainer a': {
        margin: '0.5rem 0.25rem',
        border: '1px solid',
        borderColor: 'primary',
        ':hover': {
          color: 'white',
          backgroundColor: 'primary'
        }
      },
      '.address': {
        textAlign: ['', '', '', 'right']
      },
      '.gonationLogo': {
        filter: 'brightness(0) invert(1)'
      },
      '.socialIconsContainer svg': {
        width: '50px',
        height: '50px',
        marginRight: '0.5rem',
        border: 'solid 1px',
        borderColor: 'primary',
        borderRadius: '500px',
        padding: '0.25rem',
        transition: 'all ease-in-out 0.5s',
        path: {
          fill: 'primary'
        },
        ':hover': {
          path: {
            fill: 'light'
          }
        }
      }
    },
    logo: {
      maxHeight: '300px'
    },
    logoColumn: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: '1'
    },
    column: {
      flexDirection: 'column',
      padding: 2,
      width: ['100%', '100%', '60%'],
      alignItems: ['center', 'center', 'center', 'flex-end'],

      div: {
        justifyContent: ['', '', '', 'flex-end'],
        margin: '0.5rem',
        alignItems: ['', '', '', 'flex-end']
      }
    },

    popUpContentBoxesContainer: {
      margin: '0.5rem',
      '& > button': {
        padding: '1',
        fontSize: '0.8rem'
      }
    }
  }
}

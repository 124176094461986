export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.containerScrolled': {
      backgroundColor: 'primary',
      '.navItem': {
        color: 'white'
      }
    },
    img: {
      filter: 'none!important'
    },
    '.hamburger': {
      borderColor: 'white',
      div: {
        backgroundColor: 'white'
      }
    },
    '.navMenuOpen': {
      img: {
        maxWidth: 125,
        margin: 'auto'
      }
    }
  },

  footer: {
    backgroundColor: 'primary',
    '.multiButtonContainer': {
      display: 'none' // hide the site map in the footer
    },
    '.text, .copyright': {
      color: 'white'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {},
  subtitle: {},
  text: {},

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.hero_content_container': {
      backgroundColor: `rgba(0,0,0,.6)`
      //   backgroundColor: 'rgba(1, 110, 56, .3)',
    },
    justifyContent: 'flex-start',
    alignItems: ['center', '', 'flex-end'],
    '.section': {
      margin: ['', '', '0rem 0rem 0rem 1rem']
    },
    '.title': {
      fontSize: ['2.5rem', '3.5rem', '4.5rem'],
      border: 'none',
      fontFamily: 'display',
      fontWeight: 'normal',
      paddingLeft: '0rem',
      letterSpacing: '-3px',
      lineHeight: '1'
    },
    '.subtitle': {
      fontSize: ['1.5rem', '2rem', '2.5rem'],
      borderBottom: '1px solid white'
    },
    '.text, .title, .subtitle, p': {
      color: 'white!important'
    },
    '.ctaButton': {
      backgroundColor: 'secondary',
      color: 'primary'
    }
  },

  homepageHeroShout: {
    display: 'none !important'
  },

  shoutSection: {
    '.shoutWidget': {
      display: 'none'
    },
    shoutSection: {
      '.shoutWidget': {
        display: 'none'
      },
      padding: ['1rem', '3rem 2rem', '4rem 3rem', '4rem 5rem', '4rem 10rem'],
      // marginBottom: '3rem',
      '.imageContainer ': {
        width: ['', '50%', '40%']
      },
      '.content': {
        width: ['', '50%', '60%'],
        color: 'white',
        textAlign: 'left',
        alignItems: 'flex-start',
        padding: ['1rem', '2rem', '3rem', '3rem']
      },
      '.date, .title, .text': {
        textAlign: 'left'
      },

      '.title': {
        fontSize: '1.5rem',
        textTransform: 'uppercase',
        borderBottom: '3px solid',
        borderColor: 'grey',
        paddingBottom: '1rem'
      },

      '.date': {
        opacity: '0.8'
      },

      '.text': {
        fontSize: ['1.3rem', '1.1rem', '1.3rem'],
        lineHeight: ['1.5', '', '', '2']
        // borderLeft: '3px solid #be2f27',
        // paddingLeft: '2rem',
      },

      '.image': {
        borderRadius: '0px',
        borderLeft: '6px solid',
        borderColor: 'primary'
      }
    }
  },

  homepageShout: {
    flexDirection: ['column', 'column', 'row'],
    '.imageContainer': {
      width: ['100%', '100%', '40%'],
      maxHeight: ['', '', '400px'],
      order: ['3', '', 'unset']
    },
    '.image': {
      padding: ['0rem', '', '1rem', '2rem'],
      objectFit: 'cover',
      maxWidth: 300
    },
    '.content': {
      backgroundColor: ['', '', 'transparent'],
      color: 'text',
      margin: ['', '', '2rem 1rem'],
      width: ['100%', '100%', '60%'],
      border: 'solid 2px',
      borderColor: 'secondary',
      padding: ['', '', '0.5rem 5rem 0.5rem 2rem'],

      backgroundColor: 'black'
    },
    '.text': {
      lineHeight: '1.5',
      color: ['text', '', 'text'],
      fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
      letterSpacing: '1px',
      fontFamily: 'heading'
      // fontFamily: 'heading',
    },
    '.title': {
      width: ['3rem', '', '', '4rem'],
      textTransform: 'uppercase',
      color: ['light', '', 'light'],
      letterSpacing: '3px'
    },
    '.date': {
      color: ['text', '', 'text']
      // display: 'none',
    },

    '.shoutCTA': {
      variant: 'buttons.primary'
    }
  },

  homepageShout: {
    backgroundColor: 'background',
    '.title': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black']
    },
    '.text': {
      fontFamily: 'Handy Casual Condensed',
      fontSize: ['1.6rem', '', '2rem'],
      order: ['4', '', '3'],
      border: ['', '', 'solid 9px'],
      borderColor: ['', '', 'black'],
      fontFamily: 'display',
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['black', '', '', 'secondary']
    },
    '.date': {
      backgroundColor: '#202020',
      border: ['', '', '', '#ffffff solid 9px'],
      borderColor: ['', '', '', 'black'],
      fontSize: '0.8rem'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black'],
      order: ['', '', '4']
    },
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    margin: '0rem 0rem'
  },

  homepageAbout: {
    padding: '1rem',
    '.imageContainer': {
      minHeight: '50vh'
    },

    '.title': {
      borderColor: 'primary',
      borderBottom: '2px solid',
      width: 'fit-content',
      fontSize: '2rem',
      letterSpacing: '2px'
    },
    '.content': {
      backgroundColor: 'secondary'
    }
  },

  eventSideBy: {
    padding: '1rem',
    '.imageContainer': {
      minHeight: '50vh'
    },

    '.image1': {
      width: '100%'
    },

    '.title': {
      borderColor: 'primary',
      borderBottom: '2px solid',
      width: 'fit-content',
      fontSize: '2rem',
      letterSpacing: '2px'
    },
    '.content': {
      backgroundColor: 'secondary'
    }
  },

  homepageMenu: {
    maxHeight: ['', '', '50vh'],
    padding: '1rem',
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary',
      width: 'fit-content',
      fontSize: '2rem',
      letterSpacing: '2px'
    },
    '.content': {
      border: 'solid 1px',
      margin: ['', '', '0rem 0rem 0rem 1rem'],
      width: ['', '', 'calc(50% - 1rem)'],
      backgroundColor: 'secondary'
    }
  },

  homepageGallery: {
    padding: '1rem',
    '.imageContainer': {
      minHeight: '50vh'
    },
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary',
      width: 'fit-content',
      fontSize: '2rem',
      letterSpacing: '2px'
    },
    '.content': {
      border: 'solid 1px',
      backgroundColor: 'black'
    }
  },

  homepageEvents: {
    maxHeight: ['', '', '50vh'],
    padding: '1rem',
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary',
      width: 'fit-content',
      fontSize: '2rem',
      letterSpacing: '2px'
    },
    '.content': {
      border: 'solid 1px',
      margin: ['', '', '0rem 0rem 0rem 1rem'],
      width: ['', '', 'calc(50% - 1rem)'],
      backgroundColor: 'secondary'
    }
  },

  contentBoxesTitle: {
    padding: ['1rem', '2rem 1rem', '2rem'],
    '.section': {
      alignItems: 'flex-start',
      margin: '1rem',
      maxWidth: ['', '', '70%']
    },
    '.title': {
      textAlign: 'left',
      fontSize: ['2rem', '3rem', '4.5rem'],
      letterSpacing: '-2px',
      fontFamily: 'display',
      fontWeight: 'normal'
    },
    '.subtitle': {
      textAlign: 'left',
      color: 'secondary',
      borderBottom: '2px solid',
      paddingBottom: '0.5rem'
    }
  },

  homepageContactUs: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '8rem 1rem'],
    '.title': {
      fontSize: ['', '', '4.5rem'],
      // borderTop: '2px solid',
      // borderBottom: '2px solid white',
      padding: '2rem 1rem'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },
    '.text, p': {
      color: 'white'
    }
  },

  privateParties: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: '#cec08f',
    backgroundImage: `url('https://www.transparenttextures.com/patterns/natural-paper.png')`
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {
    '.menuItemName': {
      color: '#111'
    },
    '.cellName': {
      color: 'white',
      fontSize: ['2rem']
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px',
        filter: 'brightness(0) invert(1)'
      }
    },

    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    },
    '.title': {
      display: 'block',
      textAlign: 'center'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      textTransform: 'uppercase',
      color: 'light'
    },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '9rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    }
  },

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '1.75rem', '2rem'],
      color: 'primary',
      marginBottom: '1.5rem',
      borderBottom: '2px solid',
      borderColor: 'primary'
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    iframe: { display: 'none' },
    '.content_container': {
      width: '100%'
    }
  },

  sideBySide1: {
    backgroundColor: 'black!important',
    padding: ['1rem', '', '2rem'],
    '.lazyload-wrapper': {
      minHeight: ['', '', '50vh'],
      maxHeight: ['', '', '80vh'],
      justifyContent: 'flex-start'
    },

    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  propertyContentIntro: {
    variant: 'customVariants.sideBySide1',
    padding: ['2rem', '3rem', '4rem', '5rem'],
    maxWidth: '1300px',
    margin: '0rem auto',
    overflow: 'hidden',
    'div.lazyload-wrapper': {
      position: 'relative',
      margin: '5%',
      height: ['100vw', '70vw', '70vw'],
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        paddingBottom: '100%'
      },
      img: {
        zIndex: '5',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        height: '100%'
      },
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        backgroundColor: '#d1cdc0',
        position: 'absolute',
        right: '-1rem',
        top: '-1rem',
        zIndex: '0'
      }
    }
  },

  propertyContentBlock1: {
    backgroundColor: 'black!important',
    variant: 'customVariants.propertyContentIntro',
    'div.lazyload-wrapper': {
      position: 'relative',
      margin: '5%',
      height: ['100vw', '60vw', '60vw'],
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        paddingBottom: '100%'
      },
      img: {
        zIndex: '5',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        height: '100%'
      },
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        backgroundColor: '#d1cdc0',
        position: 'absolute',
        left: '-1rem',
        top: '-1rem',
        zIndex: '0'
      }
    }
  }
}
